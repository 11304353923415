import {useTheme} from "@emotion/react";
import {AppBar, Button, Link, Toolbar} from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTranslation} from "react-i18next";

const Home = () => {
  const mdUp = useMediaQuery(theme => theme.breakpoints.down("md"));
  const theme = useTheme();
  const {t} = useTranslation();

  return (
    <>
      <Box sx={{mx: 3, mt: 2}}>
        <AppBar
          position="static"
          sx={{
            backgroundColor: "white",
            borderRadius: 99,
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            padding: "0 16px",
            border: "1px solid rgba(0, 0, 0, 0.1)",
          }}
        >
          <Toolbar sx={{display: "flex", justifyContent: "space-between", width: "100%"}}>
            <Box display="flex" alignItems="center">
              <Link href="/" style={{display: "flex", alignItems: "center"}}>
                <img
                  src="/Logo_Small.png"
                  alt="Logo"
                  style={{height: "40px", marginRight: "16px"}}
                />
              </Link>
            </Box>

            <Box sx={{flexGrow: 1, display: "flex", justifyContent: "center"}}>
              {[
                "Katılımcı Havuzuna Katıl",
                "Hakkımızda",
                "Hizmetler",
                "Araştırmalar",
              ].map((page, index) => (
                <Typography
                  key={index}
                  component={Button}
                  to={`/${page.toLowerCase()}`} // Dynamically generate the path
                  sx={{
                    fontSize: 14,
                    fontWeight: "400",
                    color: "black",
                    cursor: "pointer",
                    // marginX: "16px",
                    borderRadius: "4px",
                    // "&:hover": {
                    //   backgroundColor: "rgba(0, 0, 0, 0.05)",
                    //   textDecoration: "none",
                    // },
                  }}
                >
                  {page}
                </Typography>
              ))}
            </Box>
            <Box>
              <Link href="/auth/login">
                <Button color="primary" variant="contained" sx={{marginRight: "8px"}}>
                  Login
                </Button>
              </Link>
              <Link href="/auth/register">
                <Button color="primary" variant="outlined">
                  {t("auth.register")}
                </Button>
              </Link>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>

      <Container>
        <img
          src="/name.svg"
          alt="Logo"
          style={{height: "400px", width: "100%", marginTop: 100}}
        />

        <img
          src="/logo.png"
          alt="Logo"
          style={{height: "200px", width: "100%", marginTop: 100, objectFit: "contain"}}
        />
      </Container>
    </>
  );
};

export default Home;
