import {createContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {submissionService} from "src/services/project/submission";
import {Survey} from "src/entities/project/survey/survey";
import {projectService} from "src/services/project/project";

export const SurveyEngineContext = createContext({
  isCompleted: false,
  blockQuestions: null,
  surveyQuestions: {},
  surveyAnswers: {},
});

function shuffleQuestions(pages) {
  const shuffleQuestions = [];
  for (const page of pages) {
    shuffleQuestions.push(...page.questions.filter(question => question.is_random));
  }
  const updatedBlockPages = [];
  for (const page of pages) {
    const pageQuestions = [];
    for (const question of page.questions) {
      if (question.is_random) {
        const randomIndex = Math.floor(Math.random() * shuffleQuestions.length);
        const randomQuestion = shuffleQuestions[randomIndex];
        pageQuestions.push({
          ...randomQuestion,
          page_id: page.page_id,
        });
        shuffleQuestions.splice(randomIndex, 1);
      } else {
        pageQuestions.push({
          ...question,
          page_id: page.page_id,
        });
      }
    }
    updatedBlockPages.push({
      ...page,
      questions: pageQuestions,
    });
  }
  return updatedBlockPages;
}

export const SurveyEngineProvider = props => {
  const {children, submissionStatus} = props;
  const {uuid} = useParams();
  const [survey, setSurvey] = useState(null);
  const [isCompleted, setIsCompleted] = useState(false);
  const [blockQuestions, setBlockQuestions] = useState(null);
  const [surveyAnswers, setSurveyAnswers] = useState({});
  const [surveyQuestions, setSurveyQuestions] = useState(null);
  const [ignoreRequire, setIgnoreRequire] = useState(false);
  const [totalQuestionCount, setTotalQuestionCount] = useState(0);
  const [remainingQuestionCount, setRemainingQuestionCount] = useState(0);

  useEffect(() => {
    projectService.retrieveProjectSurvey(uuid).then(surveyData => {
      setSurvey(new Survey(surveyData));

      submissionService.getSubmission(uuid).then(submission => {
        if (submission) {
          setIsCompleted(submission.is_completed);
          const questionAnswerMap = submission.answers.reduce((acc, answer) => {
            const {question, ...rest} = answer;
            acc[answer.question] = answer;
            return acc;
          }, {});
          setSurveyAnswers(questionAnswerMap);
        }

        projectService.listSurveyQuestions(uuid).then(blockData => {
          setTotalQuestionCount(blockData.remaining_question_count);
          setRemainingQuestionCount(blockData.remaining_question_count);
          const blockQuestions = shuffleQuestions(blockData.results);
          setBlockQuestions({
            ...blockData,
            results: blockQuestions,
          });
          const surveyQuestions = blockData.results.reduce((accumulator, page) => {
            accumulator = {
              ...accumulator,
              ...page.questions.reduce((acc, question) => {
                acc[question.id] = question;
                return acc;
              }, {}),
            };
            return accumulator;
          }, {});
          setSurveyQuestions(surveyQuestions);
        });
      });
    });
  }, []);

  const handleGetNextBlock = async elementId => {
    await projectService.listSurveyQuestions(uuid, elementId).then(blockData => {
      const blockQuestions = shuffleQuestions(blockData.results);
      setBlockQuestions({
        ...blockData,
        results: blockQuestions,
      });
      setRemainingQuestionCount(blockData.remaining_question_count);
      const surveyQuestions = blockData.results.reduce((accumulator, page) => {
        accumulator = {
          ...accumulator,
          ...page.questions.reduce((acc, question) => {
            acc[question.id] = question;
            return acc;
          }, {}),
        };
        return accumulator;
      }, {});
      setSurveyQuestions(prevData => ({
        ...prevData,
        ...surveyQuestions,
      }));
    });
  };

  const handleIgnoreRequire = _ => {
    setIgnoreRequire(!ignoreRequire);
  };

  const handleQuestionAnswer = (question, answer, extra) => {
    const prevAnswer = surveyAnswers[question.id] ?? null;
    const answerData = {
      id: prevAnswer ? prevAnswer.id : null,
      text: answer,
      question: question.id,
      embedded_text: null,
      page_id: question.page_id,
      ...extra,
    };
    setSurveyAnswers(prevData => ({
      ...prevData,
      [question.id]: answerData,
    }));
  };

  const handlePageAnswers = async (pageId, pageQuestionsCount, surveyParameters) => {
    const data = {
      status: submissionStatus,
      answers: Object.values(surveyAnswers).filter(answer => answer.page_id === pageId),
      parameters: surveyParameters,
    };
    const answerResponse = await submissionService.submitPageAnswers(uuid, data);
    setRemainingQuestionCount(remainingQuestionCount - pageQuestionsCount);
  };

  const handleResetPreviewSubmission = async _ => {
    await submissionService.deletePreviewSubmission(uuid).then(_ => {
      setSurveyAnswers({});
    });
  };

  const handleCompleteSubmission = () => {
    submissionService.completeSubmission(uuid);
  };

  return (
    <SurveyEngineContext.Provider
      value={{
        survey,
        blockQuestions,
        surveyAnswers,
        ignoreRequire,
        surveyQuestions,
        isCompleted,
        totalQuestionCount,
        remainingQuestionCount,

        handleIgnoreRequire,
        handleResetPreviewSubmission,

        handleGetNextBlock,
        handleQuestionAnswer,
        handlePageAnswers,
        handleCompleteSubmission,
      }}
    >
      {children}
    </SurveyEngineContext.Provider>
  );
};

export const SurveyEngineConsumer = SurveyEngineContext.Consumer;
