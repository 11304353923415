export const trTranslations = {
  mySurveys: "Anketlerim",
  openSurveys: "Açık Anketler",
  completedSurveys: "Tamamlanan Anketler",
  rewards: "Ödüller",
  accountInfo: "Hesap Bilgileri",
  tableHeaders: {
    title: "Başlık",
    questions: "Sorular",
    responses: "Yanıtlar",
    completion: "Tamamlama",
    created: "Oluşturulma Tarihi",
    updated: "Güncellenme Tarihi",
    actions: "Eylemler",
  },
  prev: "Önceki Buton",
  next: "Sonraki Buton",
  noDataFound: "Veri bulunamadı",
  settings: {
    surveyTitle: "Anket Başlığı",
    live: "Canlı",
  },
  buttons: {
    downloadDraftExcel: "Taslak Excel Dosyasını İndir",
  },
  importExcelButtonLabel: "Excel Dosyasından İçe Aktar",
  importExcelDialogTitle: "Excel Dosyası İçe Aktar",
  importExcelNameLabel: "İsim",
  importFileButtonLabel: "Dosyayı İçe Aktar",
  createSurveyButtonLabel: "Oluştur",
  createSurveyDialogTitle: "Anket Oluştur",
  nameInputLabel: "Ad",
  createButtonLabel: "Oluştur",
  cancelButtonLabel: "İptal",
  copyLink: "Bağlantıyı Kopyala",
  copySurvey: "Anketi Kopyala",
  delete: "Sil",
  deleteSurveyTitle: "Anketi Sil",
  DeleteSurveyConfirmation:
    "{{surveyTitle}} anketi kalıcı olarak silinecek. Emin misiniz?",

  cancel: "İptal",
  sections: {
    survey: "Anket",
    preview: "Önizleme",
    distributions: "Dağıtımlar",
    results: "Sonuçlar",
    analyticsDashboard: "Analitik Grafik Ekranı",
  },
  basicSettings: {
    title: "Temel Ayarlar",
    language: "Dil",
    languageDescription:
      "Anketinizi yazdığınız dili seçmenizi öneririz. Yanıt veri de bu dilde görüntülenecektir.",
    previousButton: "Önceki Buton",
    previousButtonDescription: "Önceki butonu etkinleştir/devre dışı bırak.",
    ipBlock: "IP Engelleme",
    ipBlockDescription: "Aynı IP'den birden fazla yanıtı engelle.",
  },
  writeSomething: "Bir şeyler yazın",
  unavailableSurveyMessage: "Kullanılamayan Anket Mesajı",
  buttonLabels: {
    update: "Güncelle",
    reset: "Sıfırla",
  },
  blockSettings: {
    editBlock: "Blok Düzenle",
    displayLogicWarning:
      "Aynı blok içinde görüntüleme mantığı ve rastgeleleştirme kullanmaya çalışılıyor. Bu kombinasyon beklenildiği gibi çalışmayabilir.",
    randomizeQuestions: "Soruları Rastgeleleştir",
    addPageBreak: "Tüm Sorulara Sayfa Sonu Ekle",
    makeAllRequired: "Tüm Soruları Zorunlu Yap",
  },
  editor: {
    endPageLabel: "Son Sayfa",
    createBlockTooltip: "Soru Bloğu Oluştur",
    editBlockTooltip: "Soru Bloğunu Düzenle",
    deleteBlockTooltip: "Soru Bloğunu Sil",
    blockDeletionConfirmation: "bloğu kalıcı olarak silinecek. Emin misin?",
  },
  downloadResults: "Sonuçları İndir",
  resetSurvey: "Anketi Sıfırla",
  questionLabel: "Soru Etiketi",
  duplicate: "Kopyala",
  shuffleQuestion: "Seçenekleri Karıştır",
  deleteQuestion: "Soruyu Sil",
  ignoreRequire: "Zorunlu Cevaplamayı Yoksay",
  ignorePreviewData: "Önizleme Verilerini Yoksay",
  addOption: "Seçenek Ekle",

  label: "Varsayılan Soru Etiketi",
  choice1: "Seçenek 1",
  choice2: "Seçenek 2",
  choice3: "Seçenek 3",
  defaultQuestion: "Varsayılan Soru Başlığı",
  save: "Kaydet",
  reset: "Sıfırla",

  EditQuestion: "Soruyu Düzenle",
  QuestionType: "Soru Tipi",
  Text: "Metin",
  Settings: "Ayarlar",
  Behaviours: "Davranışlar",
  MultipleChoice: "Çoktan Seçmeli",
  ShortText: "Kısa Metin",
  TextGraphic: "Metin / Grafik",
  SurveyQuestion: "Anket Sorusu",
  EmbeddedDataField: "Gömülü Veri Alanı",
  EditQuestionTextDialogTitle: "Soru Metnini Düzenle",
  PlaceholderWriteSomething: "Bir şeyler yazın",
  CancelButton: "İptal",

  SaveButton: "Kaydet",
  RequiredLabel: "Gerekli",
  ContentTypeLabel: "İçerik Türü",
  TextLabel: "Metin",
  NumberLabel: "Sayı",
  EmailAddressLabel: "E-posta Adresi",
  RequiredQuestionError: "Bu soru zorunludur.",
  textInputTypeError: "Metin girişi geçersiz.",
  integerInputTypeError: "Sayı girişi geçersiz.",
  emailInputTypeError: "E-posta girişi geçersiz.",
  DisplayLogic: "Görüntüleme Mantığı",
  DownloadQRCode: "QR Kodunu İndir",
  CopyLink: "Bağlantıyı Kopyala",
  AddElement: "Öğe Ekle",
  Link: "Bağlantı",
  QRCode: "QR Kodu",
  CreateQuestion: "Soru Oluştur",
  AddPageBreak: "+ Sayfa Sonu Ekle",
  LogoutButton: "Çıkış Yap",
  AddBlock: "Blok Ekle",
  AddBranch: "Dal Ekle",
  successfullyUpdated: "Başarıyla Güncellendi",

  builder: "Oluşturucu",
  surveyFlow: "Anket akışı",
  surveyOptions: "Anket seçenekleri",

  auth: {
    login: "Giriş yap",
    register: "Kayıt ol",
    resetPassword: {
      title: "Şifre Yenile",
      textLabel: "Email Adresi veya Telefon numarası",
      buttonLabel: "Aktivasyon linkini gönder",
      invalidInputMessage:
        "Geçerli bir email veya telefon numarası (05329993377) girilmelidir.",
      confirmationMessage:
        "Eğer bilgileriniz geçerliyse aktivasyon için mail/SMS gönderilecek. Mail adresinizi veya telefonunuzu kontrol edebilirsiniz. Sorularınız için: katilimcihavuzu@galataanket.com",
    },
    emailAddress: "E-posta Adresi",
    password: "Şifre",
    continue: "Devam et",
    sendnewpassword: "Send a new password",
    forgotPassword: "Şifrenizi mi unuttunuz?",
    noAccount: "Hesabınız yok mu?",
  },
};
