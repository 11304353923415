import axiosInstance from "src/services/axiosInstance";

class ProjectService {
  async retrieveProjectSurvey(uuid) {
    return await axiosInstance
      .get(`/projects/${uuid}`)
      .then(survey => {
        return survey?.data;
      })
      .catch(err => {
        console.error("Error: ", err);
      });
  }

  async listSurveyQuestions(surveyId, element) {
    return await axiosInstance
      .get(`/projects/${surveyId}/questions${element ? `?element=${element}` : ""}`)
      .then(block => {
        return block?.data;
      })
      .catch(err => {
        console.error("Error: ", err);
      });
  }
}

export const projectService = new ProjectService();
