import {createContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {paths} from "src/pages/paths";
import {ACCESS_TOKEN_KEY} from "src/services/axiosInstance";
import {authService} from "src/services/auth";

const initialState = {
  token: localStorage.getItem(ACCESS_TOKEN_KEY),
  isInitialized: true,
  isAuthenticated: false,
  user: null,
  email: null,
};

export const AuthContext = createContext({
  state: initialState,
});

export const AuthProvider = props => {
  const {children} = props;
  const navigate = useNavigate();
  const [state, setState] = useState(initialState);

  useEffect(() => {
    const token = localStorage.getItem(ACCESS_TOKEN_KEY);

    if (token) {
      authService.me().then(response => {
        if (response) {
          setState({
            isInitialized: true,
            isAuthenticated: true,
            user: response,
            token: token,
            email: response?.email,
          });
        } else {
          setState({
            isInitialized: true,
            isAuthenticated: false,
            user: null,
            token: false,
            email: response?.null,
          });
          signOut();
        }
      });
    }
  }, []);

  const signIn = async (email, password) => {
    return await authService
      .signIn(email, password)
      .then(response => {
        setState({
          isInitialized: true,
          isAuthenticated: true,
          user: response.user,
          token: response?.access,
          email: response?.email,
        });
      })
      .catch(err => {
        throw err;
      });
  };

  const register = async emailOrPhone => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^05\d{9}$/;

    const data = {};
    if (emailRegex.test(emailOrPhone)) {
      data["email"] = emailOrPhone;
    } else if (phoneRegex.test(emailOrPhone)) {
      data["phone"] = emailOrPhone;
    }

    return await authService.register(data);
  };

  const signOut = async () => {
    return await authService.signOut().then(_ => {
      setState({
        isInitialized: true,
        isAuthenticated: false,
        user: null,
        token: false,
        email: null,
      });
      navigate(paths.auth.login);
    });
  };

  const resetPassword = async emailOrPhone => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^05\d{9}$/;

    const data = {};
    if (emailRegex.test(emailOrPhone)) {
      data["email"] = emailOrPhone;
    } else if (phoneRegex.test(emailOrPhone)) {
      data["phone"] = emailOrPhone;
    }
    return await authService.resetPassword(data);
  };

  return (
    <AuthContext.Provider
      value={{
        state,

        signIn,
        register,
        signOut,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const AuthConsumer = AuthContext.Consumer;
